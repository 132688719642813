import { fetchZonesFromAos } from "@/api/aos-back";
import {
  duplicateZones,
  fetchZones,
  retrieveZone,
  updateZone,
} from "@/api/atm-back";
import ZoneAsset from "@/models/ZoneAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";

const getters = {
  all() {
    return ZoneAsset.query()
      .where("isCaseAsset", true)
      .with("aosItemData")
      .with("aosItemData.storeyData")
      .get();
  },
};

const actions = {
  fetchZonesForBuilding: async (_, { buildingPath, metadata = {} }) => {
    metadata = {
      ...metadata,
      filters: JSON.stringify({
        ...metadata.filters,
        path__startswith: buildingPath,
      }),
      pagination: JSON.stringify({
        page_size: 500,
      }),
    };
    const response = await fetchZones(metadata);
    const reponseAos = await fetchZonesFromAos(metadata);

    const data = response.map((s) => ({ ...s }));
    await AosVuexOrmModel["AosZone"].insertOrUpdate({
      data: reponseAos,
    });
    await ZoneAsset.insertOrUpdate({
      data: response,
    });
    return data;
  },
  retrieveZone: async (_, { assetZoneUuid, metadata = {} }) => {
    const response = await retrieveZone(assetZoneUuid, metadata);
    await ZoneAsset.insertOrUpdate({ data: [response] });
    return response;
  },
  updateZone: async (_, { assetZoneData, metadata = {} }) => {
    const response = await updateZone(assetZoneData, metadata);
    await ZoneAsset.insertOrUpdate({ data: [response] });
    return response;
  },
  duplicateZones: async (
    context,
    { sourceZone, targetZones, fieldsToDuplicate, metadata = {} },
  ) => {
    await duplicateZones(sourceZone, targetZones, fieldsToDuplicate, metadata);
    return;
  },
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
};
