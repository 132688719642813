import { useService } from "@/setup/connectClient";
import { MissionController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/atm_pb";

const client = useService(MissionController);

// == =========== ==
// == Mission API ==
// == =========== ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchMissions = async (metadata = {}) => {
  const response = await client.list({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const updateMission = async (missionData, metadata = {}) => {
  const response = await client.update(missionData, { headers: metadata });
  return response;
};
