import {
  archiveMaterial,
  createMaterial,
  fetchMaterials,
  updateMaterial,
} from "@/api/materials";
import Material from "@/models/Material.js";
import { objectFieldsCamelToSnakeCase } from "@/utils/helpers";

const state = {
  n_materials: 0,
};

const getters = {
  getAllMaterials: () => {
    return Material.query().get();
  },
  getMaterialsByUUIDs: () => (uuids) => {
    return Material.query().whereIdIn(uuids).get();
  },
  getMaterialsCount: (state) => {
    return state.n_materials;
  },
};

const actions = {
  fetchMaterials: async ({ commit }, { filters, pagination }) => {
    const metadata = {
      filters: JSON.stringify({
        ...objectFieldsCamelToSnakeCase(filters),
      }),
      pagination: JSON.stringify({
        page: pagination?.page,
        page_size: pagination?.itemsPerPage,
      }),
    };
    const response = await fetchMaterials(metadata);
    let materialResponse = response.results;
    commit("SET_MATERIALS_COUNT", response.count);
    await Material.insertOrUpdate({
      data: materialResponse,
    });
    return materialResponse;
  },

  createMaterial: async ({ commit }, material) => {
    try {
      const response = await createMaterial(material);
      await Material.insert({ data: response });
      commit("SET_MATERIALS_COUNT", state.n_materials + 1);
      return response;
    } catch (error) {
      console.error("Failed to create material:", error);
    }
  },
  updateMaterial: async (context, material) => {
    try {
      const response = await updateMaterial(material);
      await Material.update({ data: [response] });
      return response;
    } catch (error) {
      console.error("Failed to update material:", error);
    }
  },

  archiveMaterial: async (_, material) => {
    const response = await archiveMaterial(material);
    return await Material.update({
      data: [response],
    });
  },
};

const mutations = {
  SET_MATERIALS_COUNT(state, count) {
    state.n_materials = count;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
