import PermeaCalculationLine from "@/models/PermeaCalculationLine";
import PermeaTag from "@/models/PermeaTag";
import {
  createPermeaCalculationLine,
  destroyPermeaCalculationLine,
  fetchPermeaCalculationLine,
  fetchPermeaTag,
  updatePermeaCalculationLine,
} from "../../api/atm-back";

const state = {};

const getters = {
  getVerticalPermeaTag() {
    return PermeaTag.query().where("calculationType", "VERTICAL").get();
  },
  getHorizontalPermeaTag() {
    return PermeaTag.query().where("calculationType", "HORIZONTAL").get();
  },
  getVolumePermeaTag() {
    return PermeaTag.query().where("calculationType", "VOLUME").get();
  },
  getVerticalPermeaCalculationLine() {
    return PermeaCalculationLine.query()
      .where("calculationType", "VERTICAL")
      .with("permeaTagsData")
      .get();
  },
  getHorizontalPermeaCalculationLine() {
    return PermeaCalculationLine.query()
      .where("calculationType", "HORIZONTAL")
      .with("permeaTagsData")
      .get();
  },
  getVolumePermeaCalculationLine() {
    return PermeaCalculationLine.query()
      .where("calculationType", "VOLUME")
      .with("permeaTagsData")
      .get();
  },
};

const actions = {
  async fetchPermeaTags() {
    const response = await fetchPermeaTag();
    await PermeaTag.deleteAll();
    await PermeaTag.insert({
      data: response.results,
    });
  },

  async fetchPermeaCalculationLines(context, assetZoneUuid) {
    const metadata = {
      filters: JSON.stringify({
        zone: assetZoneUuid,
      }),
    };
    const response = await fetchPermeaCalculationLine(metadata);

    await PermeaCalculationLine.deleteAll();
    await PermeaCalculationLine.insert({
      data: response.results,
    });
  },
  async createPermeaCalculationLine(_, permeaCalculationLineData) {
    const response = await createPermeaCalculationLine(
      permeaCalculationLineData,
    );
    await PermeaCalculationLine.insertOrUpdate({
      data: [response],
    });
  },
  async deletePermeaCalculationLine(_, permeaCalculationLineUuid) {
    await destroyPermeaCalculationLine(permeaCalculationLineUuid);
    await PermeaCalculationLine.delete(permeaCalculationLineUuid);
  },
  async updatePermeaCalculationLine(_, permeaCalculationLineData) {
    const response = await updatePermeaCalculationLine(
      permeaCalculationLineData,
    );
    await PermeaCalculationLine.insertOrUpdate({
      data: [response],
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
