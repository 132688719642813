import { useService } from "@/setup/connectClient";
import { ContactController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/atm_pb";

const client = useService(ContactController);

// == =========== ==
// == Contact API ==
// == =========== ==

/**
 *
 * @param {*} contactData
 * @param {*} metadata
 * @returns
 */
export const updateContact = async (contactData, metadata = {}) => {
  const response = await client.update(contactData, { headers: metadata });
  return response;
};
