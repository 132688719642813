import { useService } from "@/setup/connectClient";
import { buildPartialUpdateFields } from "@/utils/utilsGrcpRest";
import {
  PermeaTagController,
  PermeaCalculationLineController,
} from "@socotec.io/socio-grpc-api/connect/services/atm_back/permea_pb";

const excludedKeys = ["createdAt", "updatedAt"];

const permeaTagClient = useService(PermeaTagController);
const permeaCalculationLineClient = useService(PermeaCalculationLineController);

// == ========== ==
// == PERMEA API ==
// == ========== ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchPermeaTag = async (metadata = {}) => {
  const response = await permeaTagClient.list({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchPermeaCalculationLine = async (metadata = {}) => {
  const response = await permeaCalculationLineClient.list(
    {},
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} permeaCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const createPermeaCalculationLine = async (
  permeaCalculationLineData,
  metadata = {},
) => {
  const response = await permeaCalculationLineClient.create(
    permeaCalculationLineData,
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} permeaCalculationLineDataUuid
 * @param {*} metadata
 * @returns
 */
export const destroyPermeaCalculationLine = async (uuid, metadata = {}) => {
  const response = await permeaCalculationLineClient.destroy(
    { uuid },
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} permeaCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const updatePermeaCalculationLine = async (
  permeaCalculationLineData,
  metadata = {},
) => {
  const PartialUpdateFields = buildPartialUpdateFields(
    permeaCalculationLineData,
    excludedKeys,
  );

  const response = await permeaCalculationLineClient.partialUpdate(
    {
      ...permeaCalculationLineData,
      PartialUpdateFields,
    },
    { headers: metadata },
  );
  return response;
};
