import adminChildren from "@/router/childrens/admin/admin-children";
import casesChildren from "@/router/childrens/atm-front/cases-childrens";
import i18n from "@/setup/i18n";
import store from "@/store";

const Pilotage = () =>
  import(/* webpackChunkName: "Grpc" */ "@/components/atm-front/Pilotage");

const Cases = () =>
  import(/* webpackChunkName: "Rest" */ "@/components/atm-front/Cases");

const Admin = () =>
  import(/* webpackChunkName: "Rest" */ "@/components/atm-front/Admin");

const MyProfile = () =>
  import(/* webpackChunkName: "Rest" */ "@/components/atm-front/MyProfile");

const privilegedAccess = async (to, from, next) => {
  const user = store.getters["user/getCurrentUser"];
  if (user.isPrivileged()) {
    next();
  } else {
    await store.dispatch(
      "notifications/showErrorNotification",
      i18n.t("AccessDenied"),
    );
    next({ name: "casesList" });
  }
};

export default [
  {
    path: "",
    name: "home",
    redirect: { name: "casesList" },
  },
  {
    path: "cases",
    component: Cases,
    children: casesChildren,
  },
  {
    path: "pilot",
    name: "pilotage",
    component: Pilotage,
  },
  {
    path: "profile",
    name: "edit-profile", // INFO - MS - route name comes from SocioMainNavbarUserMenu (socio-vue-components)
    component: MyProfile,
  },
  {
    path: "admin",
    component: Admin,
    children: adminChildren,
    beforeEnter: privilegedAccess,
  },
];
