import { useService } from "@/setup/connectClient";
import { AgencyController } from "@socotec.io/socio-grpc-api/connect/services/user_management/entities_pb";
import {
  RoleUserController,
  UserController,
} from "@socotec.io/socio-grpc-api/connect/services/user_management/user_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const client = useService(UserController);
const roleClient = useService(RoleUserController);
const agencyClient = useService(AgencyController);

export const retrieveBySocotecCode = async (
  socotecCode,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await client.retrieveBySocotecCode(
      { socotecCode: socotecCode },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const retrieveByUuid = async (
  uuid,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await client.retrieve(
      { uuid: uuid },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const fetchUsers = async (uuids) => {
  let metadata = {};
  if (uuids) {
    metadata.filters = JSON.stringify({
      is_in_uuids: uuids,
    });
  }
  const response = await client.list({}, { headers: metadata });
  return response.results;
};

export const listUser = async (metadata) => {
  const response = await client.list({}, { headers: metadata });

  return response;
};

export const listAgencyCode = async () => {
  return await listAll(agencyClient.list, {}, {});
};

export const listRole = async (user) => {
  const response = await roleClient.userRolesWithDatasList(
    { uuid: user.usermanagementUuid },
    {},
  );

  return response.results;
};
