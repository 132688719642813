import { useService } from "@/setup/connectClient";
import { ConnectError, Code } from "@connectrpc/connect";
import { CaseInfoController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/atm_pb";

const client = useService(CaseInfoController);

// == ============ ==
// ** CaseInfo API **
// == ============ ==

/**
 *
 * @param {*} caseNumber
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const retrieveCaseInfoByCaseNumber = async (
  caseNumber,
  metadata = {},
  failIfNoFound = false,
) => {
  try {
    const response = await client.retrieveCaseByCaseNumber(
      { caseNumber },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (
      error instanceof ConnectError &&
      error.code === Code.NotFound &&
      !failIfNoFound
    ) {
      return undefined;
    }
    throw error;
  }
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const createCaseInfo = async (caseData, metadata = {}) => {
  const response = await client.create(caseData, { headers: metadata });

  return response;
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const updateCaseInfo = async (caseData, metadata = {}) => {
  const response = await client.update(
    {
      ...caseData,
      uuid: caseData.atmUuid,
    },
    { headers: metadata },
  );

  return response;
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const synchroniseCaseMissions = async (
  caseData,
  force,
  metadata = {},
) => {
  const response = await client.synchroniseCaseMissions(
    {
      uuid: caseData.atmUuid,
      force,
    },
    { headers: metadata },
  );

  return response;
};

export const fetchCases = async (metadata = {}) => {
  const response = await client.list({}, { headers: metadata });
  return response;
};
