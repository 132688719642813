import { SOCOTEC_GROUPS } from "@/models/enums/UserRoles";
import { User as BaseUser } from "@socotec.io/socio-vue-components";

export default class User extends BaseUser {
  static entity = "users";

  static fields() {
    return {
      ...super.fields(),
      certificationNumber: this.string(""),
    };
  }

  isManager() {
    return this.isInGroup(SOCOTEC_GROUPS.APP_MANAGER);
  }
  isMaterialManager() {
    return this.isInGroup(SOCOTEC_GROUPS.MATERIAL_MANAGER);
  }
  isAdmin() {
    return this.isManager() || this.isDev();
  }
  isPrivileged() {
    return this.isAdmin() || this.isMaterialManager();
  }
}
