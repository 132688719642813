import { useServiceFactory } from "@socotec.io/socio-grpc-api/connect";
import store from "@/store";

const interceptors = [];

// __CONNECT_WEB_DEVTOOLS__ is loaded in as a script, so it is not guaranteed to be loaded before your code.
if (window.__CONNECT_WEB_DEVTOOLS__ !== undefined) {
  interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
}
// To get around the fact that __CONNECT_WEB_DEVTOOLS__ might not be loaded, we can listen for a custom event,
// and then push the interceptor to our array once loaded.
window.addEventListener("connect-web-dev-tools-ready", () => {
  if (typeof window.__CONNECT_WEB_DEVTOOLS__ !== "undefined") {
    interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
  }
});

const tokenGetter = () => store.getters["oidc/oidcAccessToken"];

export const useService = useServiceFactory(
  tokenGetter,
  process.env.VUE_APP_SOCIO_GRPC_API_ENV,
  process.env.VUE_APP_SERVICE_SLUG,
  interceptors,
);
