import AtmCase from "@/models/AtmCase";
import {
  CompanyProjectRole,
  Contributor,
} from "@socotec.io/socio-vue-components";
import { Model } from "@vuex-orm/core";
export default class GeneralSettings extends Model {
  static entity = "GeneralSettings";

  static fields() {
    return {
      uuid: this.uid(),
      // Building and Housing
      individualBuildingLength: this.number(0),
      individualHousingLength: this.number(0),
      collectiveBuildingLength: this.number(0),
      collectiveHousingLength: this.number(0),
      isConstructionNew: this.string("yes"),
      hasExtension: this.string("no"),
      // Portions
      effectivePortionsLength: this.number(1),
      // Build Permit
      submissionDate: this.attr(""),
      issuanceDate: this.attr(""),
      buildPermitNumber: this.attr(""),
      // Neighborhood
      groundTransportInfrastructures: this.attr([]),
      aerodromeNoiseZone: this.string(""),
      facadeInsulation: this.string(""),
      socotecReportNumber: this.string(""),
      // EO Acoustic
      acousticMission: this.string(""),
      acousticContributor: this.string(""),
      eoContributor: this.hasOne(Contributor, "uuid", "acousticContributor"),
      // LC - INFO - 04/12/2024 - This is corresponding to an companyProjectRole uuid
      acousticCompany: this.string(""),
      eoCompanyProjectRole: this.hasOne(
        CompanyProjectRole,
        "uuid",
        "acousticCompany",
      ),
      // Technical Inspector
      tiMissionConception: this.boolean(false),
      tiMissionExecution: this.boolean(false),
      tiMissionPhh: this.boolean(false),
      tiMissionContributor: this.string(""),
      tiContributor: this.hasOne(Contributor, "uuid", "tiMissionContributor"),
      // LC - INFO - 04/12/2024 - This is corresponding to an companyProjectRole uuid
      tiMissionCompany: this.string(""),
      tiCompanyProjectRole: this.hasOne(
        CompanyProjectRole,
        "uuid",
        "tiMissionCompany",
      ),
      // Case
      caseInfo: this.string(""),
      atmCase: this.hasOne(AtmCase, "atmUuid", "caseInfo"),
    };
  }
}
