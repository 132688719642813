import BuildingAsset from "@/models/BuildingAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import {
  retrieveBuilding,
  bulkCreateBuildings,
  updateBuilding,
  fetchBuildings,
} from "@/api/atm-back";
import {
  retrieveBuildingWithLocations,
  retrieveBuildingAos,
} from "@/api/aos-back";
import _ from "lodash";

const state = {
  selectedBuilding: null,
};

const getters = {
  getCaseBuildings() {
    return BuildingAsset.query()
      .where("isCaseAsset", true)
      .with("aosItemData")
      .with("aosItemData.locations")
      .get();
  },
  selectedBuilding(state) {
    return BuildingAsset.query().where("uuid", state.selectedBuilding).first();
  },
};

const actions = {
  retrieveBuilding: async (_, { assetBuildingUuid, metadata = {} }) => {
    const response = await retrieveBuilding(assetBuildingUuid, metadata);
    await BuildingAsset.insertOrUpdate({ data: [response] });
    return response;
  },
  retrieveFullBuilding: async (_, { assetBuildingUuid, metadata = {} }) => {
    const response = await retrieveBuilding(assetBuildingUuid, metadata);
    const aosResponse = await retrieveBuildingWithLocations(
      response.aosItem,
      metadata,
    );
    aosResponse.locations.every((location) => {
      if (location.isPrimaryLocation) {
        response.parcelCodes = location.parcelCodes.map((code) => code.code);
        return false;
      }
      return true;
    });

    await BuildingAsset.insertOrUpdate({ data: [response] });
    return response;
  },
  updateBuilding: async (_, { assetBuildingData, metadata = {} }) => {
    const response = await updateBuilding(assetBuildingData, metadata);
    BuildingAsset.update({
      where: response.uuid,
      data: { ...response, isCaseAsset: true },
    });
    return response;
  },
  bulkCreate: async (_, { data, insertData }) => {
    if (!data || !data.length) return [];

    data.forEach((buildingAssetData) => {
      AosVuexOrmModel["AosBuilding"].insertOrUpdate({
        data: { ...buildingAssetData.aosItemData, isDisplayed: true },
      });
    });
    const results = await bulkCreateBuildings(data);
    await BuildingAsset.insertOrUpdate({
      data: results.map((buildingAsset) => ({
        ...buildingAsset,
        ...insertData,
      })),
    });
    return results;
  },
  fetchAosBuilding: async (__, { metadata = {} }) => {
    const buildings = await fetchBuildings(metadata);
    BuildingAsset.insertOrUpdate({ data: buildings });

    for (const building of buildings) {
      try {
        const buildingData = await retrieveBuildingAos(
          building.aosItem,
          metadata,
        );
        await AosVuexOrmModel["AosBuilding"].insertOrUpdate({
          data: [
            {
              ..._.omit(buildingData, ["locations"]),
              locationsList: buildingData.locations,
            },
          ],
        });
      } catch (error) {
        console.error("Error while fetching AOS data", error);
      }
    }
  },
  setSelectedBuilding(context, selectedBuilding) {
    context.commit("SET_SELECTED_BUILDING", selectedBuilding);
  },
};

const mutations = {
  SET_SELECTED_BUILDING: (state, selectedBuilding) => {
    state.selectedBuilding = selectedBuilding;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
