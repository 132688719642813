import { useService } from "@/setup/connectClient";
import { CompanyController } from "@socotec.io/socio-grpc-api/connect/services/contributors/contributors_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const client = useService(CompanyController);

export const companyClient = {
  listAllObjects: async (metadata) => {
    const response = await listAll(client.list, {}, metadata);
    return response;
  },
  list: async (metadata) => {
    const headers = metadata;
    const response = await client.list({}, { headers });
    return response.results;
  },
  create: async (company) => {
    const response = await client.create(company);
    return response;
  },
  update: async (company) => {
    const response = await client.update(company);
    return response;
  },
};
