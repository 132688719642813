import { useService } from "@/setup/connectClient";
import {
  BuildingController,
  ZoneController,
} from "@socotec.io/socio-grpc-api/connect/services/aos_back/structure_pb";

const buildingClient = useService(BuildingController);
const zoneClient = useService(ZoneController);

/**
 *
 * @param {aosBuildingUuid} uuid - AssetBuilding uuid
 * @param {*} headers
 * @returns
 */
export const retrieveBuildingWithLocations = async (uuid, headers = {}) => {
  const response = await buildingClient.retrieveWithLocations(
    { uuid },
    { headers },
  );
  return response;
};

/**
 *
 * @param {aosBuildingUuid} uuid - AssetBuilding uuid
 * @param {*} headers
 * @returns
 */
export const retrieveBuildingAos = async (uuid, headers = {}) => {
  const response = await buildingClient.retrieve({ uuid }, { headers });
  return response;
};

/**
 *
 * @param {object} headers - headers containing filters
 * @return {object}
 */
export const fetchZonesFromAos = async (headers = {}) => {
  const response = await zoneClient.list({}, { headers });
  return response.results;
};
