const ReportList = () =>
  import(
    /* webpackChunkName: "ReportList" */ "@/components/atm-front/cases/case/Reports/ReportList"
  );
const AcousticMeasuresStepper = () =>
  import(
    /* webpackChunkName: "AcousticMeasuresStepper" */ "@/components/atm-front/cases/case/Reports/AcousticMeasuresStepper"
  );

const AcousticAttestationStepper = () =>
  import(
    /* webpackChunkName: "AcousticAttestationStepper" */ "@/components/atm-front/cases/case/Reports/AcousticAttestationStepper"
  );

export default [
  {
    path: "",
    name: "ReportList",
    component: ReportList,
  },
  {
    path: "acoustic-measures/:reportTemplateUuid",
    name: "AcousticMeasuresStepper",
    component: AcousticMeasuresStepper,
  },
  {
    path: "acoustic-attestation/:reportTemplateUuid",
    name: "AcousticAttestationStepper",
    component: AcousticAttestationStepper,
  },
];
