import { ref } from "vue";
import store from "@/store";

const isInProduction = () => {
  return process.env.VUE_APP_SOCIO_GRPC_API_ENV === "production";
};

const hasCode = (codes) => {
  return codes.some((code) =>
    store.getters["missions/getAllMissions"].some(
      (mission) => mission.offerItemCode === code,
    ),
  );
};

export function useSubNavigations(i18n) {
  const messages = {
    en: {
      identityCard: "Identity card",
      documentsList: "Documents",
      permaMission: "Permea enveloppe",
      dpeMission: "DPE",
      ventilationMission: "Ventilation",
      re2020Mission: "Attestation RE 2020",
      acoustic: "Acoustic",
      contributors: "Contributors",
      reports: "Reports",
    },
    fr: {
      identityCard: "Fiche identité",
      documents: "Documents",
      permaMission: "Permea enveloppe",
      dpeMission: "DPE",
      ventilationMission: "Ventilation",
      re2020Mission: "Attestation RE 2020",
      acoustic: "Acoustique",
      contributors: "Intervenants",
      reports: "Livrables",
    },
  };

  i18n.mergeLocaleMessage("fr", messages.fr);
  i18n.mergeLocaleMessage("en", messages.en);

  const navItems = ref([
    {
      label: i18n.t("identityCard"),
      routeName: "CaseIdentityCardDefault",
      display: true,
    },
  ]);

  const fetchMissionsList = async (queryAsObject) => {
    if (store.getters["cases/getCurrentCase"]?.atmUuid) {
      try {
        queryAsObject["case"] = store.getters["cases/getCurrentCase"].atmUuid;
        await store.dispatch("missions/fetchMissions", queryAsObject);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getNavItems = async () => {
    await fetchMissionsList({});

    const permaMissionCodes = ["MKAA", "MKAE"];
    const dpeMissionCodes = ["HKCK"];
    const ventilationMissionCodes = ["MKAB", "MKAD"];
    const re2020MissionCodes = ["HKCH"];
    const acousticMissionCodes = ["HLCA", "MLAB"];

    if (hasCode(permaMissionCodes)) {
      navItems.value.push({
        label: i18n.t("permaMission"),
        routeName: "MissionPermea",
        display: true,
      });
    }

    if (hasCode(dpeMissionCodes)) {
      navItems.value.push({
        label: i18n.t("dpeMission"),
        routeName: "MissionDPE",
        display: true,
      });
    }

    if (hasCode(ventilationMissionCodes)) {
      navItems.value.push({
        label: i18n.t("ventilationMission"),
        routeName: "MissionVentilation",
        display: true,
      });
    }

    if (hasCode(re2020MissionCodes)) {
      navItems.value.push({
        label: i18n.t("re2020Mission"),
        routeName: "MissionRE2020",
        display: true,
      });
    }

    if (hasCode(acousticMissionCodes)) {
      navItems.value.push({
        label: i18n.t("acoustic"),
        routeName: "Operation",
        display: true,
        disabled: isInProduction(),
      });
    }

    navItems.value.push(
      {
        label: i18n.t("contributors"),
        routeName: "Contributors",
        display: true,
        disabled: isInProduction(),
      },
      {
        label: i18n.t("documents"),
        routeName: "Documents",
        display: true,
      },
      {
        label: i18n.t("reports"),
        routeName: "ReportList",
        display: true,
        disabled: isInProduction(),
      },
    );

    return navItems;
  };

  return {
    getNavItems,
  };
}
