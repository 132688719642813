import * as atmBackApi from "@/api/atm-back";
import PortionObservable from "@/models/PortionObservable";

const state = {};

const getters = {
  getPortionsObservablesByPortionUuid: () => (portionUuid) => {
    return PortionObservable.query().where("portion", portionUuid).get();
  },
  getPortionsObservablesByPortionUuidAndAosItemUuid:
    () => (portionUuid, aosItemUuid) => {
      return PortionObservable.query()
        .where("portion", portionUuid)
        .where("aosItem", aosItemUuid)
        .first();
    },
};

const actions = {
  async fetchPortionObservable(_, portionUuid) {
    const metadata = {
      filters: JSON.stringify({
        portion: portionUuid,
      }),
    };
    const response = await atmBackApi.fetchPortionObservable(metadata);

    await PortionObservable.create({ data: response });
  },

  async createOrDeletePortionObservables(_, [portionUuid, aosItems]) {
    const response = await atmBackApi.batchCreateOrDeletePortionObservables({
      portionUuid,
      aosItems,
    });
    await PortionObservable.create({ data: response });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
