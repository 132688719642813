import { Model } from "@vuex-orm/core";

export default class DpeWindowsAreaCalculationLine extends Model {
  static entity = "DpeWindowsAreaCalculationLine";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      zone: this.attr(),
      width: this.attr(0),
      height: this.attr(0),
      northQuantity: this.attr(0),
      southQuantity: this.attr(0),
      eastQuantity: this.attr(0),
      westQuantity: this.attr(0),
      label: this.attr(""),
    };
  }
}
