import { useService } from "@/setup/connectClient";
import { RoleController } from "@socotec.io/socio-grpc-api/connect/services/contributors/contributors_pb";

const client = useService(RoleController);

export const roleClient = {
  list: async () => {
    const headers = {
      filters: JSON.stringify({
        service_id: "atm",
      }),
      pagination: JSON.stringify({
        page: 1,
        pageSize: 100,
      }),
    };

    const response = await client.list({}, { headers });

    return response.results;
  },
};
