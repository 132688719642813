const Operation = () =>
  import(
    /* webpackChunkName: "Informations" */ "@/components/atm-front/cases/case/Acoustic/Operation"
  );
const Portions = () =>
  import(
    /* webpackChunkName: "Portions" */ "@/components/atm-front/cases/case/Acoustic/Portions"
  );

const PortionDetail = () =>
  import(
    /* webpackChunkName: "PortionDetail" */ "@/components/atm-front/cases/case/Acoustic/PortionDetail"
  );

export default [
  {
    path: "",
    name: "Operation",
    component: Operation,
  },
  {
    path: "portions",
    name: "Portions",
    component: Portions,
  },
  {
    path: "portions/:portionUuid",
    name: "PortionDetail",
    component: PortionDetail,
  },
];
