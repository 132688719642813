const CasesList = () =>
  import(
    /* webpackChunkName: "CasesList" */ "@/components/atm-front/cases/CasesList"
  );
const Case = () =>
  import(/* webpackChunkName: "Case" */ "@/components/atm-front/cases/Case");

import store from "@/store";
import CaseChildrens from "./case/case-children";

const fetchCaseData = async (to, from, next) => {
  if (to.params.caseNumber !== from.params.caseNumber) {
    await store.dispatch(
      "cases/retrieveCaseByCaseNumber",
      to.params.caseNumber,
    );
  }

  const currentCase = store.getters["cases/getCurrentCase"];

  const metadata = {
    filters: JSON.stringify({
      object_id: currentCase.uuid,
      object_type: "CASE",
    }),
  };

  await store.dispatch("roles/fetchRoles");
  await store.dispatch("contributors/fetchContributors", metadata);
  await store.dispatch("companies/fetchAllCompanies", { metadata });
  await store.dispatch(
    "companyProjectRoles/fetchCompanyProjectRoles",
    metadata,
  );

  next();
};

export default [
  {
    path: "",
    name: "casesList",
    component: CasesList,
  },
  {
    path: ":caseNumber",
    component: Case,
    children: CaseChildrens,
    beforeEnter: fetchCaseData,
  },
];
