import axios from "axios";

const restDocumentClient = axios.create({
  baseURL: process.env.VUE_APP_U_DOCUMENT_BACK_URL,
  headers: {},
});

/**
 * @param {FormData} docFile
 * @param {"put"|"post"} method
 * @returns {Promise}
 */
restDocumentClient.uploadBimDocument = async (docFile, method) => {
  return await (
    method === "post" ? restDocumentClient.post : restDocumentClient.put
  )("/documents/upload_document/", docFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const initDocumentAuth = (accessToken) => {
  restDocumentClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default restDocumentClient;
export { restDocumentClient, initDocumentAuth };
