import { Model } from "@vuex-orm/core";
import PermeaTag from "./PermeaTag";

export default class PermeaCalculationLine extends Model {
  static entity = "PermeaCalculationLine";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      length: this.attr(0),
      width: this.attr(0),
      height: this.attr(0),
      formula: this.attr(""),
      result: this.attr(0),
      formulaMode: this.attr(""),
      calculationType: this.attr(""),
      permeaTags: this.attr([]),
      permeaTagsData: this.hasManyBy(PermeaTag, "permeaTags"),
    };
  }
}
