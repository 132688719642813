const state = {
  totalBoxCount: 0,
};

const getters = {
  getTotalBoxCount: (state) => {
    return state.totalBoxCount;
  },
};

const actions = {
  async fetchTotalBoxCount(context /* , { assetSite } */) {
    // fake
    const totalBoxCount = Math.floor(Math.random() * 15);
    await context.commit("SET_TOTAL_BOX_COUNT", totalBoxCount);
  },
};

const mutations = {
  SET_TOTAL_BOX_COUNT: (state, totalBoxCount) => {
    state.totalBoxCount = totalBoxCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
