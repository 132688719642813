import { useService } from "@/setup/connectClient";
import { buildPartialUpdateFields } from "@/utils/utilsGrcpRest";
import { DpeWindowsAreaCalculationLineController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/dpe_pb";

const client = useService(DpeWindowsAreaCalculationLineController);
const excludedKeys = ["createdAt", "updatedAt"];

// == ========== ==
// == PERMEA API ==
// == ========== ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDpeWindowsAreaCalculationLines = async (metadata = {}) => {
  const response = await client.list({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} dpeCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const createDpeCalculationLine = async (
  dpeCalculationLineData,
  metadata = {},
) => {
  const response = await client.create(dpeCalculationLineData, {
    headers: metadata,
  });
  return response;
};

/**
 *
 * @param {*} uuid
 * @param {*} metadata
 * @returns
 */
export const destroyDpeWindowsAreaCalculationLine = async (
  uuid,
  metadata = {},
) => {
  const response = await client.destroy({ uuid }, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} dpeWindowsAreaCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const updateDpeWindowsAreaCalculationLine = async (
  dpeWindowsAreaCalculationLineData,
  metadata = {},
) => {
  const PartialUpdateFields = buildPartialUpdateFields(
    dpeWindowsAreaCalculationLineData,
    excludedKeys,
  );

  const request = {
    ...dpeWindowsAreaCalculationLineData,
    PartialUpdateFields,
  };

  const response = await client.partialUpdate(request, { headers: metadata });
  return response;
};

export const createDpeCsv = async (requestData, metadata = {}) => {
  const response = await client.exportCSV(requestData, { headers: metadata });
  return response;
};
