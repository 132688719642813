import { useService } from "@/setup/connectClient";
import {
  GeneralSettingsController,
  PortionController,
  PortionAosObservableController,
} from "@socotec.io/socio-grpc-api/connect/services/atm_back/acoustic_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const generalSettingsClient = useService(GeneralSettingsController);
const portionClient = useService(PortionController);
const portionAosObservableClient = useService(PortionAosObservableController);

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchGeneralSettings = async (metadata = {}) => {
  const response = await generalSettingsClient.list({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const createGeneralSettings = async (
  generalSettingsData,
  currentCase,
  metadata = {},
) => {
  const response = await generalSettingsClient.create(
    {
      ...generalSettingsData,
      caseInfo: currentCase.atmUuid,
    },
    { headers: metadata },
  );

  return response;
};

export const updateGeneralSettings = async (
  generalSettingsData,
  currentCase,
  metadata = {},
) => {
  const response = await generalSettingsClient.update(
    {
      ...generalSettingsData,
      caseInfo: currentCase.atmUuid,
    },
    { headers: metadata },
  );

  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchPortion = async (metadata = {}) => {
  const response = await portionClient.list({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchAllPortion = async (metadata = {}) => {
  const response = await listAll(portionClient.list, {}, metadata);
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const retrievePortion = async (portionUuid, metadata = {}) => {
  const response = await portionClient.retrieve(
    { uuid: portionUuid },
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const createPortion = async (
  portionData,
  currentCase,
  metadata = {},
) => {
  const response = await portionClient.create(
    {
      ...portionData,
      caseInfo: currentCase.atmUuid,
    },
    { headers: metadata },
  );

  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const updatePortion = async (
  portionData,
  currentCase,
  metadata = {},
) => {
  const data = {
    ...portionData,
    caseInfo: currentCase.atmUuid,
  };

  if (!portionData.measurementDocumentUuid) {
    data.measurementDocumentUuid = null; // avoid sending empty string that will be rejected by the server
  }

  const response = await portionClient.update(data, { headers: metadata });

  return response;
};

export const BulkUpdatePortionMaterials = async (
  portionMaterials,
  metadata = {},
) => {
  const response = await portionClient.bulkUpdatePortionMaterials(
    portionMaterials,
    { headers: metadata },
  );
  return response;
};

export const BulkUpdatePortionContributors = async (
  portionContributors,
  metadata = {},
) => {
  const response = await portionClient.bulkUpdatePortionContributors(
    portionContributors,
    { headers: metadata },
  );
  return response;
};

export const BulkUpdatePortionCompanies = async (
  portionCompanies,
  metadata = {},
) => {
  const response = await portionClient.bulkUpdatePortionCompanies(
    portionCompanies,
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const destroyPortion = async (portionUuid, metadata = {}) => {
  await portionClient.destroy({ uuid: portionUuid }, { headers: metadata });
};

export const fetchPortionObservable = async (metadata = {}) => {
  const response = await listAll(portionAosObservableClient.list, {}, metadata);
  return response;
};

export const batchCreateOrDeletePortionObservables = async (
  portionObservablesData,
  metadata = {},
) => {
  const response = await portionAosObservableClient.batchCreateOrDelete(
    portionObservablesData,
    { headers: metadata },
  );
  return response.results;
};

export const createPortionObservable = async (
  portionObservableData,
  metadata = {},
) => {
  const response = await portionAosObservableClient.create(
    portionObservableData,
    { headers: metadata },
  );

  return response;
};

export const destroyPortionObservable = async (uuid, metadata = {}) => {
  await portionAosObservableClient.destroy({ uuid }, { headers: metadata });
};
