import { useService } from "@/setup/connectClient";
import { MaterialController } from "@socotec.io/socio-grpc-api/connect/services/materials/material_services_pb";

const client = useService(MaterialController);

export const fetchMaterials = async (metadata = {}) => {
  const response = await client.list({}, { headers: metadata });
  return response;
};

export const createMaterial = async (material) => {
  return await client.create(material);
};

export const archiveMaterial = async (materialUuid) => {
  const response = await client.partialUpdate({
    uuid: materialUuid,
    isArchived: true,
    PartialUpdateFields: ["is_archived"],
  });
  return response;
};

export const updateMaterial = async (material) => {
  return await client.update(material);
};
