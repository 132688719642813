import i18n from "@/setup/i18n";
import Vue from "vue";
import VueRouter from "vue-router";

import AtmFrontChildrens from "@/router/childrens/atm-front-children";

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
const AtmFront = () =>
  import(/* webpackChunkName: "AtmFront" */ "@/components/AtmFront");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AtmFront,
    children: AtmFrontChildrens,
    beforeEnter: async (to, from, next) => {
      try {
        const user = store.getters["user/getCurrentUser"];
        if (!user) {
          await store.dispatch("user/initializeCurrentUser");
        }
        next();
      } catch (err) {
        console.error(err);
        await store.dispatch(
          "notifications/showErrorNotification",
          i18n.t("UserNotFound"),
        );
      }
    },
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));

export default router;
