const CaseIdentityCard = () =>
  import(
    /* webpackChunkName: "Documents" */ "@/components/atm-front/cases/case/CaseIdentityCard"
  );

const MissionPermea = () =>
  import(
    /* webpackChunkName: "Documents" */ "@/components/atm-front/cases/case/MissionPermea"
  );

const Documents = () =>
  import(
    /* webpackChunkName: "Documents" */ "@/components/atm-front/cases/case/Documents.vue"
  );

const MissionDPE = () =>
  import(
    /* webpackChunkName: "MissionDPE" */ "@/components/atm-front/cases/case/MissionDPE"
  );

const MissionVentilation = () =>
  import(
    /* webpackChunkName: "MissionVentilation" */ "@/components/atm-front/cases/case/MissionVentilation"
  );

const MissionRE2020 = () =>
  import(
    /* webpackChunkName: "MissionRE2020" */ "@/components/atm-front/cases/case/MissionRE2020"
  );

const Acoustic = () =>
  import(
    /* webpackChunkName: "Acoustic" */ "@/components/atm-front/cases/case/Acoustic"
  );

const Contributors = () =>
  import(
    /* webpackChunkName: "Contributors" */ "@/components/atm-front/cases/case/Contributors"
  );

const Reports = () =>
  import(
    /* webpackChunkName: "Reports" */ "@/components/atm-front/cases/case/Reports"
  );

import CaseIdentityCardChildren from "./case-identity-card/case-identity-card-children";
import AcousticChildren from "./acoustic/acoustic-children";
import ReportsChildren from "./reports/reports-children";
import store from "@/store";
import allowIfNavItemNotDisabled from "./middleware/allowIfNavItemNotDisabled";

const fetchBuilding = async (to, from, next) => {
  const metadata = {
    filters: JSON.stringify({
      case: store.getters["cases/getCurrentCase"].atmUuid,
    }),
    pagination: JSON.stringify({
      // INFO - PH - 23/08/2024 - Increase pagination to 500 to make sure getting all the buildings, as there are usually only ten or so in a case.
      page_size: 500,
      page: 1,
    }),
  };
  await store.dispatch("building/fetchAosBuilding", { metadata });
  next();
};

export default [
  {
    path: "identity-card",
    component: CaseIdentityCard,
    children: CaseIdentityCardChildren,
  },
  {
    path: "documents",
    name: "Documents",
    component: Documents,
  },
  {
    path: "contributors",
    name: "Contributors",
    component: Contributors,
    beforeEnter: allowIfNavItemNotDisabled, // To Delete only for disable in prod
  },
  {
    path: "reports",
    component: Reports,
    children: ReportsChildren,
    beforeEnter: allowIfNavItemNotDisabled, // To Delete only for disable in prod
  },
  {
    path: "mission-permea",
    name: "MissionPermea",
    component: MissionPermea,
    beforeEnter: fetchBuilding,
  },
  {
    path: "mission-dpe",
    name: "MissionDPE",
    component: MissionDPE,
    beforeEnter: fetchBuilding,
  },
  {
    path: "mission-ventilation",
    name: "MissionVentilation",
    component: MissionVentilation,
    beforeEnter: fetchBuilding,
  },
  {
    path: "mission-re2020",
    name: "MissionRE2020",
    component: MissionRE2020,
    beforeEnter: fetchBuilding,
  },
  {
    path: "acoustic",
    component: Acoustic,
    children: AcousticChildren,
    beforeEnter: allowIfNavItemNotDisabled, // To Delete only for disable in prod
  },
];
