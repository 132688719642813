import { useService } from "@/setup/connectClient";
import { DocumentController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/atm_pb";

// == ============ ==
// ** Document API **
// == ============ ==

const client = useService(DocumentController);

/**
 *
 * @param {*} gedProjectId
 * @param {*} metadata
 * @returns
 */
export const getDooAppReport = async (gedProjectId, metadata = {}) => {
  const response = await client.getReportByProjectId(
    { gedProjectId },
    { headers: metadata },
  );
  return response;
};
