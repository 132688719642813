import { Report as SocioReport } from "@socotec.io/socio-vue-components";

export default class Report extends SocioReport {
  static name = "Report";

  static fields() {
    return {
      ...super.fields(),
      recipientUsermanagementUuids: this.attr([]),
      recipients: this.attr([]),
      validators: this.attr([]),
      batches: this.attr([]),
      relatedObjects: this.attr([]),
    };
  }
}
