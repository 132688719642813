import { Model } from "@vuex-orm/core";
import User from "@/models/User";
export default class Mission extends Model {
  static entity = "Mission";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      offerItemId: this.attr(""),
      offerItemCode: this.attr(""),
      offerItemStatus: this.attr(""),
      caseNumber: this.attr(""),
      speaker: this.attr(""), // case.case_manager_code: only one
      chronoId: this.attr(""), // mission.project.chronorapso or chrono_id
      phase: this.attr(""),
      da: this.attr(""),
      nbr: this.attr(0),
      saleAmount: this.attr(0), // sale_amount
      dateRFCT: this.attr(""),
      reviewContract: this.attr(""), // status of mission
      dateRdv: this.attr(""),
      comment: this.attr(""),
      case: this.attr(""), // case
      projectManagerSocotecCode: this.attr(""),
      projectManagerUsermanagementUuid: this.uid(),
      projectManager: this.belongsTo(
        User,
        "projectManagerUsermanagementUuid",
        "uuid", // INFO - FB - 15/02/2023 - Users are fetched from UserManagement and not from atm-back
      ),
      purchaseRequest: this.attr(""),
      rfctDate: this.attr(""),
    };
  }
}
