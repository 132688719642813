import { Model } from "@vuex-orm/core";

export default class Transmission extends Model {
  static entity = "Transmission";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      caseInfo: this.string(""),
      buildingData: this.attr([]),
      status: this.string(""),
      type: this.string(""),
      userUuid: this.string(""),
    };
  }
}
