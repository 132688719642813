import { resolveUser, updateUser } from "@/api/atm-back";
import { listRole, listUser } from "@/api/user-management-back";
import User from "@/models/User";

const state = {
  currentUser: null,
};

const getters = {
  getCurrentUser: (state) => {
    return state.currentUser;
  },
  getAllUsers: () => {
    return User.all();
  },
};

const actions = {
  async initializeCurrentUser({ commit }) {
    try {
      const userData = await resolveUser();
      const userRoles = await listRole(userData);

      const extractedRoleNames = [
        ...new Set(userRoles.map((obj) => obj.role.name)),
      ];
      // 21/01/2025 - LB - Add roles from user to groups to be able to test permissions in the front
      const updatedGroups = [
        ...new Set([...(userData.groups || []), ...extractedRoleNames]),
      ];

      const { users } = await User.insert({
        data: { ...userData, groups: updatedGroups, isCurrentUser: true },
      });
      commit("SET_CURRENT_USER", users[0]);
    } catch (err) {
      console.error(err);
    }
  },
  async updateCurrentUser({ rootGetters, commit }, userData) {
    try {
      const userDataResponse = await updateUser(userData);
      await User.insertOrUpdate({
        data: [userDataResponse],
      });
      const res = await commit(
        "SET_CURRENT_USER",
        new User({ ...rootGetters.getCurrentUser, ...userDataResponse }),
      );
      return res;
    } catch (err) {
      console.error(err);
    }
  },

  async searchUser(context, text) {
    const metadata = {
      filters: JSON.stringify({ search: text }),
    };
    const userDataResponse = await listUser(metadata);
    const data = await User.insertOrUpdate({ data: userDataResponse.results });
    return data.users ?? [];
  },
};

const mutations = {
  SET_CURRENT_USER: (state, user) => {
    state.currentUser = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
