import { useService } from "@/setup/connectClient";
import {
  CaseController,
  ClientController,
} from "@socotec.io/socio-grpc-api/connect/services/atlas_as_a_service/atlas_pb";

const caseClient = useService(CaseController);
const clientClient = useService(ClientController);

export const fetchCases = async (metadata = {}) => {
  const response = await caseClient.listWithProjectManagerUsermanagementUuids(
    {},
    { headers: metadata },
  );
  return response;
};

export const retrieveCaseByCaseNumber = async (
  caseNumber,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await caseClient.retrieveCaseByCaseNumber(
      { caseNumber },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const retrieveClient = async (
  uuid,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await clientClient.retrieve(
      { uuid },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};
