import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import SocioVueComponents, {
  initializeTracingAndLogging,
} from "@socotec.io/socio-vue-components";
import AosComponents from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import PackageJson from "../package.json";
import SocioManifest from "../socio-manifest.json";
initializeTracingAndLogging(SocioManifest, PackageJson, {
  useDocumentLoadInstrumentation: false,
});
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.config.productionTip = false;

Vue.use(SocioVueComponents);
store.$client = socioGrpcClient;
Vue.use(AosComponents, { store });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
