import { useService } from "@/setup/connectClient";
import { ContributorController } from "@socotec.io/socio-grpc-api/connect/services/contributors/contributors_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const client = useService(ContributorController);

export const contributorClient = {
  list: async (metadata) => {
    const response = await listAll(client.list, {}, metadata);
    return response;
  },
  create: async (contributor) => {
    const response = await client.create(contributor);
    return response;
  },
  update: async (contributor) => {
    const response = await client.update(contributor);
    return response;
  },
  destroy: async (contributor) => {
    const headers = {
      filters: JSON.stringify({
        check_company_project: false,
      }),
    };
    await client.destroy(contributor, { headers });
    return null;
  },
};
