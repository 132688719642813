import { utils } from "@socotec.io/socio-aos-component";
import SiteAsset from "@/models/SiteAsset";
import BuildingAsset from "@/models/BuildingAsset";
import StoreyAsset from "@/models/StoreyAsset";
import ZoneAsset from "@/models/ZoneAsset";
import SpaceAsset from "@/models/SpaceAsset";

export const ASSET_ITEMS = {
  site: {
    ...utils.aosConst.AOS_ITEMS.site,
    modelClass: () => SiteAsset,
    identityCard: "SiteIdentityCard",
  },

  building: {
    ...utils.aosConst.AOS_ITEMS.building,
    modelClass: () => BuildingAsset,
    identityCard: "BuildingIdentityCard",
  },

  storey: {
    ...utils.aosConst.AOS_ITEMS.storey,
    modelClass: () => StoreyAsset,
    identityCard: "StoreyIdentityCard",
  },

  zone: {
    ...utils.aosConst.AOS_ITEMS.zone,
    modelClass: () => ZoneAsset,
    identityCard: "BaseIdentityCard",
  },

  space: {
    ...utils.aosConst.AOS_ITEMS.space,
    modelClass: () => SpaceAsset,
    identityCard: "BaseIdentityCard",
  },
};

export const streamStatuses = {
  SUCCESS: "SUCCESS",
  GENERATING: "GENERATING",
  ERROR: "ERROR",
  READY: "READY",
  SENT: "SENT",
  GENERATION_FAILED: "GENERATION_FAILED",
  UPLOAD_FAILED: "UPLOAD_FAILED",
};
