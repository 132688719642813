import { Model } from "@vuex-orm/core";

export default class AtmCaseContributor extends Model {
  static entity = "AtmCaseContributor";
  static primaryKey = ["atmCaseNumber", "userMail"];

  static fields() {
    return {
      atmCaseNumber: this.attr(null),
      userMail: this.attr(null),
    };
  }
}
