import { useService } from "@/setup/connectClient";
import { TransmissionController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/transmission_pb";

const client = useService(TransmissionController);

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchTransmissions = async (metadata = {}) => {
  const response = await client.list({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} buildingData
 * @param {*} userUuid
 * @param {*} caseInfoUuid
 * @param {*} metadata
 * @returns
 */
export const createTransmission = async (
  type,
  buildingData,
  appendices,
  userUuid,
  caseInfo,
  metadata = {},
) => {
  const request = {
    caseInfo,
    userUuid,
    type,
    buildingData,
    appendices,
  };

  const response = await client.create(request, { headers: metadata });

  return response;
};
