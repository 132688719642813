import { listAgencyCode } from "@/api/user-management-back";
import { Agency } from "@socotec.io/socio-vue-components";

const getters = {
  getAllAgency: () => {
    return Agency.all();
  },
  getAgencyByUuid: () => (uuid) => {
    return Agency.query().where("uuid", uuid).first();
  },
};

const actions = {
  async fetchAgencyCode() {
    const agencyData = await listAgencyCode();

    Agency.deleteAll();
    await Agency.insert({
      data: agencyData,
    });

    return Agency.all();
  },
};

export default {
  namespaced: true,
  getters,
  actions,
};
