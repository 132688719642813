import { useService } from "@/setup/connectClient";
import { buildPartialUpdateFields } from "@/utils/utilsGrcpRest";
import { UserController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/user_pb";

const client = useService(UserController);
const excludedKeys = ["createdAt", "updatedAt"];

// == ============ ==
// ** CaseInfo API **
// == ============ ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const resolveUser = async (metadata = {}) => {
  const response = await client.resolveUser({}, { headers: metadata });
  return response;
};

/**
 *
 * @param {*} userData
 * @param {*} metadata
 * @returns
 */
export const updateUser = async (userData, metadata = {}) => {
  const PartialUpdateFields = buildPartialUpdateFields(userData, excludedKeys);

  const response = await client.partialUpdate(
    {
      ...userData,
      PartialUpdateFields,
    },
    { headers: metadata },
  );

  return response;
};
