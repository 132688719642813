export const ATM_MISSION_CODES = [
  "MKAA",
  "MKAE",
  "HKCK",
  "MKAD",
  "MKAB",
  "MLAB",
  "HLCA",
  "HKCH",
  "HKAA",
  "HKAB",
  "JABB",
  "AZBA",
  "AZBJ",
];
export const TO_BILL_OFFER_ITEM_CODES = ["1", "2", "3", "4", "5", "6"];
export const BILLED_OFFER_ITEM_CODES = ["12"];
export const TERMINATED_OFFER_ITEM_CODES = ["13"];
export const CANCELLED_OFFER_ITEM_CODES = ["14"];
export const DOOAPP_VENTILATION = "DOOAPP_VENTILATION";
export const DOOAPP_ENVELOPPE = "DOOAPP_ENVELOPPE";
export const PERRENOUD = "PERRENOUD";
