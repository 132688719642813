import * as atmBackApi from "@/api/atm-back";
import Portion from "@/models/Portion";

const state = {
  portionLength: 0,
};

const getters = {
  getAllPortions: () => {
    return Portion.query().with("document").with("atmCase").get();
  },
  getPortionById: () => (id) => {
    return Portion.query()
      .where("uuid", id)
      .with("document")
      .with("atmCase")
      .with("contributors.companyProjectRole.company|role")
      .with("companyProjectRoles.company|role")
      .first();
  },
  getPortionLength: (state) => {
    return state.portionLength;
  },
};

const actions = {
  async fetchPortionsByCase(context, metadata = {}) {
    const metadataStringified = {
      filters: JSON.stringify({
        case_info: metadata?.caseInfo,
      }),
      pagination: JSON.stringify({
        page: metadata?.page,
        page_size: metadata?.pageSize,
      }),
    };
    const response = await atmBackApi.fetchPortion(metadataStringified);
    context.commit("SET_PORTION_LENGTH", response.count);
    return await Portion.create({ data: response.results });
  },
  async fetchAllPortions(context, { filters }) {
    const metadataStringified = {
      filters: JSON.stringify({
        ...filters,
      }),
    };
    const response = await atmBackApi.fetchAllPortion(metadataStringified);
    context.commit("SET_PORTION_LENGTH", response.length);
    return await Portion.create({ data: response });
  },

  async retrievePortion(_, uuid) {
    const response = await atmBackApi.retrievePortion(uuid);
    await Portion.insert({ data: response });
  },

  async createPortion(context, [portionData, currentCase]) {
    const response = await atmBackApi.createPortion(portionData, currentCase);
    await Portion.insert({ data: response });
    context.commit("INCREMENT_PORTION_LENGTH");
    return response;
  },

  async updatePortion(_, [portionData, currentCase]) {
    const response = await atmBackApi.updatePortion(portionData, currentCase);

    await Portion.update({
      where: response.uuid,
      data: response,
    });
  },

  async BulkUpdatePortionMaterials(_, portionMaterials) {
    const response =
      await atmBackApi.BulkUpdatePortionMaterials(portionMaterials);

    await Portion.update({
      where: response.uuid,
      data: response,
    });
  },

  async BulkUpdatePortionContributors(_, portionContributors) {
    const response =
      await atmBackApi.BulkUpdatePortionContributors(portionContributors);

    await Portion.update({
      where: response.uuid,
      data: response,
    });
  },

  async deletePortion(context, portionId) {
    await atmBackApi.destroyPortion(portionId);
    await Portion.delete(portionId);
    context.commit("DECREMENT_PORTION_LENGTH");
  },

  async BulkUpdatePortionCompanyProjectRoles(_, portionCompanyProjectRoles) {
    const response = await atmBackApi.BulkUpdatePortionCompanies(
      portionCompanyProjectRoles,
    );

    await Portion.update({
      where: response.uuid,
      data: response,
    });
  },
};

const mutations = {
  SET_PORTION_LENGTH(state, length) {
    state.portionLength = length;
  },

  INCREMENT_PORTION_LENGTH(state) {
    state.portionLength++;
  },

  DECREMENT_PORTION_LENGTH(state) {
    state.portionLength--;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
