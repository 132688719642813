import { companyProjectRoleClient } from "@/api/contributors";
import {
  CompanyProjectRole,
  Contributor,
} from "@socotec.io/socio-vue-components";

const state = {};

const getters = {
  companyProjectRoles: () =>
    CompanyProjectRole.query()
      .with("contributors")
      .with("company")
      .with("role")
      .all(),
  companyProjectRoleWithNoContributorByRole:
    () =>
    (roleName = null) =>
      CompanyProjectRole.query()
        .with("company")
        .with("contributors")
        .with("role")
        .hasNot("contributors")
        .whereHas("role", (query) => {
          if (roleName) {
            query.where("name", roleName);
          }
        })
        .has("companyId")
        .get(),
  getCompanyProjectRolesByUuids:
    () =>
    (uuids = []) =>
      CompanyProjectRole.query()
        .with("company")
        .with("role")
        .with("contributors")
        .whereIdIn(uuids)
        .get(),
  getCompanyProjectRoleByUuid: () => (uuid) =>
    CompanyProjectRole.query()
      .with("company")
      .with("role")
      .with("contributors")
      .whereId(uuid)
      .first(),
};

const actions = {
  async createCompanyProjectRole(context, companyProjectRole) {
    const companyProjectRoleData =
      await companyProjectRoleClient.create(companyProjectRole);

    await CompanyProjectRole.insert({ data: companyProjectRoleData });

    return companyProjectRoleData;
  },

  updateCompanyProjectRole: async (context, companyProjectRole) => {
    const companyProjectRoleData =
      await companyProjectRoleClient.update(companyProjectRole);

    await CompanyProjectRole.update({
      data: [companyProjectRoleData],
    });

    return companyProjectRoleData;
  },

  async destroyCompanyProjectRole(context, companyProjectRole) {
    await companyProjectRoleClient.destroy(companyProjectRole);
    for (let contributor of companyProjectRole.contributors) {
      await Contributor.delete(contributor.uuid);
    }
    await CompanyProjectRole.delete(companyProjectRole.uuid);
  },

  async fetchCompanyProjectRoles(context, metadata = {}) {
    const companyProjectRolesData =
      await companyProjectRoleClient.list(metadata);

    CompanyProjectRole.deleteAll();

    const { companyProjectRole } = await CompanyProjectRole.insert({
      data: companyProjectRolesData,
    });

    return companyProjectRole;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
