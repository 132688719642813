import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import SiteAsset from "@/models/SiteAsset";
import { bulkCreateSites, fetchSites } from "@/api/atm-back";

const getters = {
  getCaseSites() {
    return SiteAsset.query()
      .where("isCaseAsset", true)
      .with("aosItemData")
      .with("aosItemData.locations")
      .get();
  },
};

const actions = {
  fetchSites: async (_, { aosSites = [], metadata = {} }) => {
    const data = await fetchSites(aosSites, metadata);
    await SiteAsset.insertOrUpdate({
      data,
    });
    return data;
  },
  bulkCreate: async (_, { data, insertData }) => {
    if (!data?.length) return [];
    data.forEach((siteAssetData) => {
      AosVuexOrmModel["AosSite"].insertOrUpdate({
        data: { ...siteAssetData.aosItemData, isDisplayed: true },
      });
    });
    const results = await bulkCreateSites(data);
    await SiteAsset.insertOrUpdate({
      data: results.map((siteAsset) => ({ ...siteAsset, ...insertData })),
    });
    return results;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
};
