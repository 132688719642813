import { useService } from "@/setup/connectClient";
import { CompanyProjectRoleController } from "@socotec.io/socio-grpc-api/connect/services/contributors/contributors_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const client = useService(CompanyProjectRoleController);

export const companyProjectRoleClient = {
  list: async (headers) => {
    const response = await listAll(client.list, {}, headers);
    return response;
  },
  create: async (companyProjectRole) => {
    const response = await client.getOrCreate(companyProjectRole);
    return response;
  },
  update: async (companyProjectRole) => {
    const response = await client.update(companyProjectRole);
    return response;
  },
  destroy: async (companyProjectRole) => {
    await client.destroy(companyProjectRole);
    return null;
  },
};
