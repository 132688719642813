import reportApi from "@/api/report-generator-back";
import Report from "@/models/Report";
import i18n from "@/setup/i18n";
import { streamStatuses } from "@/utils/const";
import { ReportTemplate } from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { ReportGeneratorController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/report_generator_pb";
import { ReportController } from "@socotec.io/socio-grpc-api/connect/services/report_generator/reports_pb";

const reportGeneratorClient = useService(ReportGeneratorController);
const reportClient = useService(ReportController);

const state = {
  reportsCount: 0,
  generatingReport: false,
};

const getters = {
  getReportsCount: (state) => {
    return state.reportsCount;
  },
  isReportGenerating: (state) => {
    return state.generatingReport;
  },
  getTemplateByUuid: () => (uuid) => {
    return ReportTemplate.query().where("uuid", uuid).first();
  },
};

const actions = {
  async fetchReportTemplates() {
    const responseObjects = await reportApi.fetchReportTemplateList();
    return await ReportTemplate.insertOrUpdate({
      data: responseObjects.results,
    });
  },
  async generateReport({ dispatch, commit }, { reportData, options }) {
    const {
      reportTemplateUuid,
      reportGenerationData,
      metadata = {},
    } = reportData;
    const { uniqueKey, form } = options;
    const generationData = {
      reportTemplateUuid,
      reportGenerationData,
    };
    commit("SET_GENERATING_REPORT", true);

    const stream = reportGeneratorClient.generate(generationData, {
      headers: metadata,
    });

    for await (const response of stream) {
      let action = {
        [streamStatuses.SUCCESS]: async () => {
          dispatch(
            "notifications/showSuccessNotification",
            i18n.t("reportMessages.generationSuccess"),
            { root: true },
          );
          dispatch("retrieveReport", response.reportUuid);
          localStorage.setItem(uniqueKey, JSON.stringify(form));
        },
        [streamStatuses.GENERATION_FAILED]: async () => {
          dispatch(
            "notifications/showErrorNotification",
            i18n.t("reportMessages.generationError"),
            { root: true },
          );
        },
        [streamStatuses.UPLOAD_FAILED]: async () => {
          dispatch(
            "notifications/showErrorNotification",
            i18n.t("reportMessages.generationError"),
            { root: true },
          );
        },
        [streamStatuses.ERROR]: async () => {
          dispatch(
            "notifications/showErrorNotification",
            i18n.t("reportMessages.generationError"),
            { root: true },
          );
        },
        [streamStatuses.GENERATING]: async () => {},
      };

      await action[response.code]();
      if (response.code !== streamStatuses.GENERATING) {
        commit("SET_GENERATING_REPORT", false);
      }
    }
  },

  async listReports({ commit }, { filters = {}, pagination = {} }) {
    const headers = {
      pagination: JSON.stringify(pagination),
    };

    const response = await reportClient.list({ Filters: filters }, { headers });
    commit("UPDATE_REPORTS_COUNT", response.count);
    const reportsList = response.results;
    await Report.insert({
      data: reportsList,
    });
  },

  async retrieveReport({ getters, commit }, reportUuid) {
    const data = await reportClient.retrieve({ uuid: reportUuid });

    await Report.insertOrUpdate({
      data,
    });
    commit("UPDATE_REPORTS_COUNT", getters.getReportsCount + 1);
  },

  async deleteReport({ commit, getters }, reportUuid) {
    await reportClient.destroy({ uuid: reportUuid });
    await Report.delete(reportUuid);
    commit("UPDATE_REPORTS_COUNT", getters.getReportsCount - 1);
  },
};

const mutations = {
  SET_GENERATING_REPORT: function (state, newValue) {
    state.generatingReport = newValue;
  },
  UPDATE_REPORTS_COUNT: function (state, newTotal) {
    state.reportsCount = newTotal;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
