import { useService } from "@/setup/connectClient";
import { camelToSnakeCase } from "@/utils/helpers";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";
import { CaseInfoAosObservableController } from "@socotec.io/socio-grpc-api/connect/services/atm_back/atm_pb";
import {
  BuildingController,
  SiteController,
  ZoneController,
} from "@socotec.io/socio-grpc-api/connect/services/atm_back/aos_pb";
import { buildPartialUpdateFields } from "@/utils/utilsGrcpRest";

const caseInfoAosObservableClient = useService(CaseInfoAosObservableController);
const buildingClient = useService(BuildingController);
const siteClient = useService(SiteController);
const zoneClient = useService(ZoneController);

const excludedKeys = ["createdAt", "updatedAt"];

export const fetchCaseAssets = async (metadata) => {
  const response = await listAll(
    caseInfoAosObservableClient.list,
    {},
    metadata,
  );
  return response;
};

export const retrieveCaseAsset = async (
  CaseInfoObservableUuid,
  metadata = {},
) => {
  const response = await caseInfoAosObservableClient.retrieve(
    { uuid: CaseInfoObservableUuid },
    { headers: metadata },
  );
  return response;
};

export const addCaseAssets = async (
  formattedAssetsData,
  caseInfoUuid,
  metadata = {},
) => {
  const response = await caseInfoAosObservableClient.addCaseInfoAssets(
    {
      caseInfoUuid,
      ...formattedAssetsData,
    },
    { headers: metadata },
  );
  return response.results;
};

export const removeCaseAssetsInAtm = async (
  selection,
  caseInfoUuid,
  metadata = {},
) => {
  const request = {
    caseInfoUuid: caseInfoUuid,
    sites: selection.site ?? [],
    buildings: selection.building ?? [],
    storeys: selection.storey ?? [],
    zones: selection.zone ?? [],
    spaces: selection.space ?? [],
  };

  return await caseInfoAosObservableClient.removeCaseInfoAssets(request, {
    headers: metadata,
  });
};

export const bulkCreateBuildings = async (buildings, metadata = {}) => {
  const response = await buildingClient.bulkCreate(
    { buildings },
    { headers: metadata },
  );
  return response.results;
};

export const fetchSites = async (aosSites, metadata = {}) => {
  const response = await siteClient.list(
    { aosItems: aosSites },
    { headers: metadata },
  );
  return response.results;
};

export const fetchBuildings = async (metadata = {}) => {
  const response = await buildingClient.list({}, { headers: metadata });
  return response.results;
};

export const siteInfoByCase = async (requestData, metadata = {}) => {
  const response = await siteClient.siteInfoByCase(requestData, {
    headers: metadata,
  });
  return response;
};

export const buildingInfoByCase = async (requestData, metadata = {}) => {
  const response = await buildingClient.buildingInfoByCase(requestData, {
    headers: metadata,
  });
  return response;
};

export const bulkCreateSites = async (bulkCreateData, metadata = {}) => {
  const sitesRequest = bulkCreateData.map((siteAssetData) => ({
    case: siteAssetData.caseInfo,
    aosItem: siteAssetData.aosItem,
    path: siteAssetData.path,
  }));
  const response = await siteClient.bulkCreate(
    { sites: sitesRequest },
    { headers: metadata },
  );
  return response.results;
};

export const retrieveBuilding = async (assetBuildingUuid, metadata = {}) => {
  const response = await buildingClient.retrieve(
    { uuid: assetBuildingUuid },
    { headers: metadata },
  );
  return response;
};

export const updateBuilding = async (assetBuildingData, metadata = {}) => {
  const PartialUpdateFields = buildPartialUpdateFields(
    assetBuildingData,
    excludedKeys,
  );

  const response = await buildingClient.partialUpdate(
    {
      ...assetBuildingData,
      PartialUpdateFields,
    },
    { headers: metadata },
  );
  return response;
};

export const fetchZones = async (metadata = {}) => {
  const response = await zoneClient.list({}, { headers: metadata });
  return response.results;
};

export const retrieveZone = async (assetZoneUuid, metadata = {}) => {
  const response = await zoneClient.retrieve(
    { uuid: assetZoneUuid },
    { headers: metadata },
  );
  return response;
};

export const updateZone = async (assetZoneData, metadata = {}) => {
  const PartialUpdateFields = buildPartialUpdateFields(
    assetZoneData,
    excludedKeys,
  );

  const response = await zoneClient.partialUpdate(
    {
      ...assetZoneData,
      PartialUpdateFields,
    },
    { headers: metadata },
  );
  return response;
};

export const duplicateZones = async (
  sourceZone,
  targetZones,
  fieldsToDuplicate,
  metadata = {},
) => {
  await zoneClient.duplicateZones(
    {
      sourceZone,
      targetZones,
      fieldsToDuplicate: fieldsToDuplicate.map((key) => camelToSnakeCase(key)),
    },
    { headers: metadata },
  );
};
