import { useService } from "@/setup/connectClient";
import {
  ReportController,
  ReportTemplateController,
} from "@socotec.io/socio-grpc-api/connect/services/report_generator/reports_pb";
import {
  MailController,
  MailTypeController,
} from "@socotec.io/socio-grpc-api/connect/services/report_generator/mails_pb";

const reportClient = useService(ReportController);
const mailClient = useService(MailController);
const mailTypeClient = useService(MailTypeController);
const reportTemplateClient = useService(ReportTemplateController);

export const retrieveLastReportForObjectId = async (
  objectId,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await reportClient.getLastReportForObjectId(
      { objectId },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const fetchMailList = async (metadata) => {
  const response = await mailClient.list({}, { headers: metadata });
  return response;
};

export const createMailType = async (data, metadata = {}) => {
  const response = await mailTypeClient.create(data, { headers: metadata });
  return response;
};

export const fetchMailTypeList = async (metadata = {}) => {
  const response = await mailTypeClient.list({}, { headers: metadata });
  return response;
};

export const createMailDocument = (data, mailGenerationData, metadata = {}) => {
  const request = {
    ...data,
    mailGenerationData,
  };

  return mailClient.create(request, { headers: metadata });
};

export const fetchReportTemplateList = async () => {
  const metadata = {
    filters: JSON.stringify({
      service_id: process.env.VUE_APP_ATM_SERVICE_ID,
    }),
  };
  const response = await reportTemplateClient.list({}, { headers: metadata });
  return response;
};

export default {
  retrieveLastReportForObjectId,
  fetchMailList,
  createMailType,
  fetchMailTypeList,
  createMailDocument,
  fetchReportTemplateList,
};
