import i18n from "@/setup/i18n";
import store from "@/store";

const AdminHomepage = () =>
  import("@/components/atm-front/admin/AdminHomepage");
const MaterialsAdmin = () =>
  import("@/components/atm-front/admin/MaterialsAdmin");

const privilegedAccess = async (to, from, next) => {
  const user = store.getters["user/getCurrentUser"];
  if (user.isAdmin() || user.isMaterialManager()) {
    next();
  } else {
    await store.dispatch(
      "notifications/showErrorNotification",
      i18n.t("AccessDenied"),
    );
    next({ name: "admin" });
  }
};

export default [
  {
    path: "",
    name: "admin",
    component: AdminHomepage,
    redirect: { name: "materialsAdmin" },
    children: [
      {
        path: "materials",
        name: "materialsAdmin",
        component: MaterialsAdmin,
      },
    ],
    beforeEnter: privilegedAccess,
  },
];
