import { Case } from "@socotec.io/socio-vue-components";
import User from "@/models/User";
import AtmCaseContributor from "@/models/AtmCaseProjectManager";

export default class AtmCase extends Case {
  static entity = "AtmCases";
  static baseEntity = super.entity;
  static primaryKey = "caseNumber";

  static fields() {
    return {
      ...super.fields(),

      // Atlas data
      agency: this.attr({}),
      caseUuid: this.attr(""),

      // ATM data
      atmUuid: this.attr(""),
      contactClient: this.attr(""),
      contact1: this.attr(""),
      contact2: this.attr(""),
      atmLabel: this.attr(""),
      description: this.attr(""),
      atmAddress: this.attr(""),
      atmPostcode: this.attr(""),
      atmCity: this.attr(""),
      comment: this.attr(""),
      salesAmount: this.attr(""),
      missionsSynchronisedStatus: this.attr(""),
      thermalRegulation: this.attr(""),
      buildPermitSubmissionDate: this.attr(""),
      buildPermitIssuanceDate: this.attr(""),
      buildPermitNumber: this.attr(""),
      groupedPermit: this.boolean(),
      contributors: this.belongsToMany(
        User,
        AtmCaseContributor,
        "atmCaseNumber",
        "userMail",
      ),
      manager: this.belongsTo(
        User,
        "managerUsermanagementUuid",
        "uuid", // INFO - FB - 15/02/2023 - Users are fetched from UserManagement and not from atm-bac
      ),
      agencyName: this.attr(""),

      salesTotalAmount: this.attr(""),

      // Report data
      rfctStatus: this.attr(""),
      gedProjectId: this.attr(""),
      rfctDate: this.attr(""),
    };
  }

  static insertFromAtmBack(data) {
    return this.insertOrUpdate({
      data: {
        uuid: data.uuid,
        label: data.atmLabel,
        atmUuid: data.uuid,
        managerUsermanagementUuid: data.managerUsermanagementUuid,
        agencyName: data.agencyName,
        contributors: data.contributors,
        ...this.atlasReconciliation(data),
      },
    });
  }

  static async createFromMultipleSource(
    atlasData,
    atmData,
    caseContributorsList,
  ) {
    return this.insertOrUpdate({
      data: {
        // - Atlas
        ...this.atlasReconciliation(atlasData),
        // - ATM
        ...this.atmReconciliation(atmData),
        // - RFCT date
        contributors: caseContributorsList,
      },
      insert: ["client"],
    });
  }

  static async updateAtmData(caseNumber, atmData) {
    return this.update({
      where: caseNumber,
      data: {
        ...AtmCase.atmReconciliation(atmData),
      },
    });
  }

  static atmReconciliation(backData) {
    return {
      caseUuid: backData.caseUuid,
      atmUuid: backData.uuid,
      atmLabel: backData.atmLabel,
      atmAddress: backData.atmAddress,
      atmPostcode: backData.atmPostcode,
      atmCity: backData.atmCity,
      description: backData.description,
      contactClient: backData.contactClient,
      contact1: backData.contact1,
      contact2: backData.contact2,
      salesAmount: backData.salesAmount,
      missionsSynchronisedStatus: backData.missionsSynchronisedStatus,
      gedProjectId: backData.gedProjectId,
      groupedPermit: backData.groupedPermit,
      buildPermitIssuanceDate: backData.buildPermitIssuanceDate,
      buildPermitSubmissionDate: backData.buildPermitSubmissionDate,
      buildPermitNumber: backData.buildPermitNumber,
      thermalRegulation: backData.thermalRegulation,
      rfctDate: backData.rfctDate,
      rfctStatus: backData.rfctSent,
      comment: backData.comment,
    };
  }

  static atlasReconciliation(backData) {
    return backData;
  }
}
